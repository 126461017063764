<template>
  <main>
    <div class="mt-0 pb-16">
      <Jumbo class="w-full" />
      <section
        class="container mx-auto w-full flex md:w-2/3 flex-col md:flex-row items-center py-12"
      >
        <div class="hidden md:block mr-12">
          <div>
            <img
              class="rounded-lg shadow-lg"
              style="width: 296px;  object-fit: cover; object-position: 50% 50%;"
              src="/avatar.jpg"
              fetchpriority="high"
              alt="Panizsamsami"
            />
            <div>
              <small>Fotograf: Tony Ullgren</small>
            </div>
          </div>
        </div>
        <div class="w-full md:w-2/3 px-4">
          <div class="md:hidden mx-auto w-1/2 px-4">
            <img
              class="rounded-lg shadow-lg"
              style="width: 296px; object-fit: cover; object-position: 50% 50%;"
              src="/avatar.jpg"
              fetchpriority="high"
              alt="Panizsamsami"
            />
            <div>
              <small>Fotograf: Tony Ullgren</small>
            </div>
          </div>
          <div class="text-center md:text-left mt-8 md:mt-0">
            <h1 class="text-4xl font-semibold">
              {{ $t('subjects.aboutMe.title') }}
            </h1>
            <p class="mt-4 text-lg font-light text-gray-700">
              {{ $t('subjects.aboutMe.sect1') }}
            </p>
            <p class="mt-4  text-lg font-extralight text-gray-700">
              {{ $t('subjects.aboutMe.sect2') }}
            </p>
            <p class="mt-4  text-lg font-extralight text-gray-700">
              {{ $t('subjects.aboutMe.sect3') }}
            </p>
            <p class="mt-4  text-lg font-extralight text-gray-700">
              {{ $t('subjects.aboutMe.sect4') }}
            </p>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import Jumbo from '../components/view/Jumbo.vue';

export default {
  components: { Jumbo },
};
</script>
