<template>
  <div id="app" class="background">
    <Topbar />
    <main>
      <router-view :key="$route.fullPath" class="" />
    </main>
    <Footer />
  </div>
</template>

<script>
import Topbar from './components/view/Topbar.vue';
import Footer from './components/view/Footer.vue';
import { Locale } from './i18n';

export default {
  components: { Topbar, Footer },
  async mounted() {
    const locale = localStorage.getItem('locale') ?? Locale.SWEDISH;
    if (locale) {
      this.$root.$i18n.locale = locale;
    }
  },
};
</script>
