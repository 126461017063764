<template>
  <main>
    <div class="mt-0">
      <Jumbo class="w-full" />
      <section class="py-12  w-2/3">
        <div class="max-w-6xl mx-auto">
          <div class="mb-8">
            <h2 class="text-4xl pl-12 font-base">
              {{ $t('menu.fee') }}
            </h2>
            <div class="mt-4  text-xl font-extralight text-gray-700  pl-12">
              {{ $t('footer.pricing') }}
              <br />
              {{ $t('footer.pricing2') }}
            </div>
            <div class="mt-4  text-xl font-extralight text-gray-700  pl-12">
              {{ $t('footer.info') }}
            </div>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import Jumbo from '../components/view/Jumbo.vue';

export default {
  components: { Jumbo },
};
</script>
