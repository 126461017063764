import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import About from '../views/About.vue';
import Contact from '../views/Contact.vue';
import Fee from '../views/Fee.vue';
Vue.use(VueRouter);

const ViewName = {
  Start: 'start',
  About: 'about',
  Fee: 'contact',
  Contact: 'fee',
};

const routes = [
  {
    path: '/',
    name: ViewName.Start,
    component: About,
    meta: { requiresAuth: false },
  },
  {
    path: '/about',
    name: ViewName.About,
    component: Home,
    meta: { requiresAuth: false },
  },
  {
    path: '/fee',
    name: ViewName.Fee,
    component: Fee,
    meta: { requiresAuth: false },
  },
  {
    path: '/contact',
    name: ViewName.Contact,
    component: Contact,
    meta: { requiresAuth: false },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = process.env.VUE_APP_NAME + ' | ' + to.meta.title;
  } else {
    document.title = process.env.VUE_APP_NAME;
  }
  next();
});
export default router;
