<template>
  <div
    class="bg-cover bg-center bg-no-repeat border-t border-b border-black"
    style="background-image: url('/site-bg.jpg');"
  >
    <div class="flex justify-center items-center py-28">
      <div class="text-white p-8 rounded-lg text-center text-shadow">
        <div class="text-7xl font-montserrat font-extralight">
          Paniz Samsami
        </div>
        <div class="text-xl mt-4 font-montserrat">
          {{ $t('headerSubText') }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.text-shadow {
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5); /* Example text shadow values */
}
</style>
