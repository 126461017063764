<template>
  <div class="bg-white">
    <div class="container mx-auto">
      <div class="flex justify-between items-center p-10">
        <a href="/" class="text-gray-800 text-3xl font-montserrat"></a>
        <div class="flex items-center space-x-12 mr-20">
          <div class="hidden md:flex justify-center">
            <router-link
              to="/"
              class="text-gray-800 font-montserrat hover:opacity-80"
              exact
              >{{ $t('menu.home') }}</router-link
            >
          </div>
          <div class="hidden md:flex justify-center">
            <router-link
              to="/about"
              class="text-gray-800 font-montserrat hover:opacity-80"
              >{{ $t('menu.about') }}</router-link
            >
          </div>
          <div class="hidden md:flex justify-center">
            <router-link
              to="/contact"
              class="text-gray-800 font-montserrat hover:opacity-80"
              >{{ $t('menu.contact') }}</router-link
            >
          </div>
          <div class="hidden md:flex justify-center">
            <router-link
              to="/fee"
              class="text-gray-800 font-montserrat hover:opacity-80"
              >{{ $t('menu.fee') }}</router-link
            >
          </div>
        </div>
        <LanguageSwitch />
        <button
          aria-label="menu"
          class="text-gray-800 ml-4 p-2 hover:bg-gray-200 lg:invisible"
          @click.prevent="showMobileMenu = true"
        >
          <img
            src="https://uploads-ssl.webflow.com/64dfc086ff137e6a5612f6ec/64dfc086ff137e6a5612f772_menu-icon.png"
            width="22"
            alt="Menu"
            class="menu-icon"
          />
        </button>
      </div>
    </div>
    <div
      v-if="showMobileMenu"
      class="fixed flex top-0 left-0 w-full h-full bg-white z-50 overflow-y-auto"
    >
      <!-- Close button -->
      <div class="text-right p-4">
        <button class="text-6xl text-gray-600" @click="showMobileMenu = false">
          &times;
        </button>
      </div>

      <!-- Navigation links -->
      <div class="justify-center p-4 items-center mx-auto mt-12 text-xl">
        <div class="justify-center" @click="showMobileMenu = false">
          <router-link
            to="/"
            class="text-gray-800 font-montserrat hover:opacity-80"
            exact
            >{{ $t('menu.home') }}</router-link
          >
        </div>
        <div class="justify-center" @click="showMobileMenu = false">
          <router-link
            to="/about"
            class="text-gray-800 font-montserrat hover:opacity-80"
            >{{ $t('menu.about') }}</router-link
          >
        </div>
        <div class="justify-center" @click="showMobileMenu = false">
          <router-link
            to="/contact"
            class="text-gray-800 font-montserrat hover:opacity-80"
            >{{ $t('menu.contact') }}</router-link
          >
        </div>
        <div class="justify-center" @click="showMobileMenu = false">
          <router-link
            to="/fee"
            class="text-gray-800 font-montserrat hover:opacity-80"
            >{{ $t('menu.fee') }}</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LanguageSwitch from '../LanguageSwitch.vue';

export default {
  components: { LanguageSwitch },
  data() {
    return {
      showMobileMenu: false,
    };
  },
};
</script>
