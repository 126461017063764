<template>
  <footer class="custombg text-white pt-6 pb-6">
    <div class="container mx-auto">
      <div class="ml-4 md:ml-0 md:flex justify-between space-x-6 text-black">
        <div
          class="md:flex mt-4 justify-start md:justify-between md:w-3/5 md:mx-auto"
        >
          <div class="mb-8 ">
            <a
              href="mailto:samsamipaniz@gmail.com?subject=Hi!"
              class="block text-center flex"
            >
              <img
                alt="Email"
                src="https://uploads-ssl.webflow.com/64dfc086ff137e6a5612f6ec/64ecedbc3661d11fda8bc953_email.png"
                class="mr-4 mb-2 w-10"
              />
              <div class="text-sm mt-2">samsamipaniz@gmail.com</div>
            </a>
          </div>

          <div class="mb-8">
            <a href="tel:+4673-9693990" class="block text-center flex">
              <img
                alt="Phone"
                src="https://uploads-ssl.webflow.com/64dfc086ff137e6a5612f6ec/64ecedec5a7bbf4b7fe050f2_phone.png"
                class="mr-4 mb-2 w-10"
              />
              <div class="text-sm mt-2">+46 73-9693990</div>
            </a>
          </div>

          <div class="mb-8">
            <a
              href="https://www.linkedin.com/in/paniz-samsami-25baa18/"
              target="_blank"
              class="block flex"
            >
              <img
                src="https://uploads-ssl.webflow.com/64dfc086ff137e6a5612f6ec/64ece9e3072e0881b098cb16_in.png"
                alt="LinkedIn"
                class="mr-4 mb-2 w-10"
              />
              <div class="text-sm mt-2">LinkedIn</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {};
</script>
<style scoped>
.custombg {
  background-color: #eadcd2;
}
</style>
