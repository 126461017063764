<template>
  <main>
    <div class="mt-0">
      <Jumbo class="w-full" />
      <section
        class="container mx-auto w-full flex md:w-2/3 flex-col md:flex-row items-center py-12"
      >
        <div class="max-w-6xl mx-auto">
          <div class="mb-8 p-6">
            <h2 class="text-4xl pl-12 font-base">
              {{ $t('menu.contact') }}
            </h2>
            <div class="mt-4  text-xl font-extralight text-gray-700  pl-12">
              {{ $t('footer.address') }}
            </div>
            <div class="mt-4  text-xl font-extralight text-gray-700  pl-12">
              <b>Email</b>: samsamipaniz@gmail.com <br />
              <b>{{ $t('phone') }}</b
              >: <a href="tel:+46 73-9693990">+46739693990</a>
            </div>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import Jumbo from '../components/view/Jumbo.vue';

export default {
  components: { Jumbo },
};
</script>
