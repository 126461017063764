<template>
  <div>
    <img
      class="hover:shadow-lg cursor-pointer"
      width="35"
      :title="$t('changeLanguage')"
      :src="getLanguageFlag"
      @click.prevent="changeLanguage(lang)"
    />
  </div>
</template>

<script>
import { Locale } from '../i18n';
export default {
  data() {
    return {
      FLAG_SWE: null,
      FLAG_UK: null,
      lang: '',
    };
  },
  computed: {
    getLanguageFlag() {
      return this.$i18n.locale === Locale.ENGLISH
        ? this.FLAG_SWE
        : this.FLAG_UK;
    },
  },
  watch: {
    '$i18n.locale': function(newLocale) {
      this.updateLangBasedOnLocale(newLocale);
    },
  },
  created() {
    require(['../assets/flag_swe.png'], module => {
      this.FLAG_SWE = module;
    });
    require(['../assets/flag_uk.png'], module => {
      this.FLAG_UK = module;
    });

    this.updateLangBasedOnLocale(this.$i18n.locale);
  },
  methods: {
    updateLangBasedOnLocale(locale) {
      switch (locale) {
        case 'en':
          this.lang = 'sv';
          break;
        default:
          this.lang = 'en';
          break;
      }
    },
    changeLanguage(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem('locale', lang);
      this.lang = lang;
    },
  },
};
</script>
