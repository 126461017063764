<template>
  <main>
    <div class="mt-0">
      <Jumbo class="w-full" />
      <section
        class="container mx-auto w-full flex md:w-2/3 flex-col md:flex-row items-center py-12"
      >
        <div class="max-w-4xl mx-auto">
          <div class="mb-8 p-6 mx-auto">
            <h2 class="text-4xl font-base md:ml-0">
              {{ $t('subjects.psychodynamic.title') }}
            </h2>
            <div class="mt-4 text-xl font-extralight text-gray-700">
              {{ $t('subjects.psychodynamic.sect1') }}
            </div>
            <div class="mt-4 text-xl font-extralight text-gray-700">
              {{ $t('subjects.psychodynamic.sect2') }}<br />
            </div>

            <div class="mt-4 text-xl font-extralight text-gray-700">
              {{ $t('subjects.psychodynamic.sect3') }}
            </div>
          </div>

          <div class="mb-8 p-6 mx-auto">
            <h2 class="text-4xl font-base md:ml-0">
              {{ $t('subjects.session.title') }}
            </h2>
            <div class="mt-4 text-xl font-extralight text-gray-700">
              {{ $t('subjects.session.sect1') }}
            </div>
            <div class="mt-4 text-xl font-extralight text-gray-700">
              {{ $t('subjects.session.sect2') }}
            </div>
          </div>

          <div class="mb-8 p-6 mx-auto">
            <h2 class="text-4xl font-base md:ml-0">
              {{ $t('subjects.confidentiality.title') }}
            </h2>
            <div class="mt-4  text-xl font-extralight text-gray-700">
              {{ $t('subjects.confidentiality.sect1') }}
            </div>
            <div class="mt-4  text-xl font-extralight text-gray-700">
              {{ $t('subjects.confidentiality.sect2') }}
            </div>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import Jumbo from '../components/view/Jumbo.vue';

export default {
  components: { Jumbo },
};
</script>
